<template>
  <div>
    <div style="display: flex; justify-content: flex-end">
      <b-button
        id="word-button"
        variant="primary"
        :disabled="isLoading"
        style="margin-left: 10px"
        @click="convertToWord()"
      >
        <b-spinner v-if="isLoading" small />
        Cetak
      </b-button>
    </div>

    <section
      id="print-section"
      style="
        background-color: white;
        padding: 4px;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        margin: 0 auto;
        width: 10.41cm;
        height: 11.05cm;
        box-sizing: border-box;
        font-size: 8px;
        overflow: hidden;
      "
    >
      <table style="width: 100%; border-collapse: collapse; height: 100%">
        <tr>
          <td colspan="2" style="padding-bottom: 2px">
            <div style="text-align: center">
              <h5 style="margin: 0">UTD RS OTO ISKANDAR DI NATA</h5>
              <p style="margin: 1px 0 2px">
                Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921 Telp.
                081312856201
              </p>
            </div>
            <hr style="margin: 0; border-top: 1px solid #ccc" />
            <hr style="margin: 1px 0; border-bottom: 1px solid #ccc" />
          </td>
        </tr>
        <tr>
          <td
            style="
              padding: 2px;
              box-sizing: border-box;
              width: 60%;
              vertical-align: top;
            "
          >
            <h6 style="margin: 0 0 2px; text-align: center; font-weight: bold">
              DONOR <span class="text-uppercase">SUKARELA</span>
            </h6>
            <div style="text-align: center; margin: 2px 0">
              <img
                :src="barcodeImages.production_result_code"
                alt="production_result_code"
                style="align-self: start; max-width: 90%; height: 70px"
              />
            </div>
            <div style="margin-top: 2px">
              <p style="margin: 0">Produk : PRC</p>
              <p style="margin: 0">Volume : 184 cc</p>
              <p style="margin: 0">Rhesus : Positif</p>
              <p class="mb-2">Antibody : Negatif</p>
              <p style="margin: 0">Aftap : 2024-09-20</p>
              <p style="margin: 0">Prod : 2024-10-02</p>
              <p style="margin: 0">EXD : 2024-10-25</p>
            </div>
            <div style="text-align: center; margin: 2px 0">
              <img
                :src="barcodeImages.code_main_bag"
                alt="code_main_bag"
                style="max-width: 80%; height: 70px"
              />
            </div>
            <p style="margin: 2px 0 0">Simpan Pada Suhu 2-6°C</p>
            <p style="margin: 0">CPDA-1</p>
          </td>
          <td
            style="
              padding: 2px;
              box-sizing: border-box;
              width: 40%;
              vertical-align: top;
            "
          >
            <h2 class="mt-3" style="text-align: center; font-weight: bold">
              RH Positif
            </h2>
            <div class="mt-3 mb-3" style="text-align: center">
              <p
                style="
                  font-weight: bold;
                  margin: 0 0 2px;
                  text-decoration: underline;
                  white-space: nowrap;
                "
              >
                NON REAKTIF TERHADAP
              </p>
              <ol style="margin: 0; text-align: left; margin-left: 4px">
                <li v-for="(test, index) in nonReactiveTests" :key="index">
                  {{ index + 1 }}. {{ test }}
                </li>
              </ol>
            </div>
            <div
              class="mt-5"
              style="
                font-weight: bold;
                text-align: center;
                border-radius: 4px;
                color: #212529;
                border: 2px solid #212529;
                padding: 2px;
                font-size: 32px;
              "
            >
              LULUS
            </div>
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import { saveAs } from "file-saver";
import { BButton, BSpinner } from "bootstrap-vue";
import {
  Document,
  Paragraph,
  Packer,
  TextRun,
  Table,
  TableCell,
  TableRow,
  BorderStyle,
  WidthType,
  AlignmentType,
  VerticalAlign,
  ImageRun,
  HeadingLevel,
  LineRuleType,
  TableBorders,
  PageBorderOffsetFrom,
  PageBorderDisplay,
  UnderlineType,
} from "docx";

export default {
  components: { BSpinner, BButton },
  data() {
    return {
      isLoading: false,
      barcodeLoaded: false,
      nonReactiveTests: ["Anti HIV", "Anti HCV", "HSBAG", "SYPHILIS"],
      barcodeImages: {
        production_result_code: "",
        code_main_bag: "",
      },
      product: {
        code_main_bag: "",
        bag_type: "",
        aftap_date: "",
        production_result_code: "",
        component: "",
        gram: "",
        cc: "",
        expired: "",
        blood: "",
        status: "",
        deleted_at: null,
        created_at: "",
        updated_at: "",
        reason_failed: null,
      },
    };
  },
  computed: {
    donor_type() {
      return this.$route.query.donor_type;
    },
    blood_type_result() {
      if (this.$route.query.blood_type_result.toUpperCase().includes("POS")) {
        return "Positif";
      }
      if (this.$route.query.blood_type_result.toUpperCase().includes("NEG")) {
        return "Negatif";
      }
      return this.$route.query.blood_type_result.toUpperCase();
    },
    component_barcode() {
      return this.$route.query.component_barcode;
    },
    blood_bag_number() {
      return this.$route.query.blood_bag_number;
    },
    component() {
      return this.$route.query.component;
    },
    volume() {
      return this.$route.query.volume;
    },
    aftapDate() {
      return this.$route.query.aftap_date;
    },
    prodDate() {
      return this.$route.query.prod_date;
    },
    expDate() {
      return this.$route.query.expired_date;
    },
    bloodType() {
      return this.product.blood.charAt(0);
    },
    rhesus() {
      return this.product.blood.charAt(1) === "+" ? "Positif" : "Negatif";
    },
    statusLabel() {
      return this.product.status === "passed" ? "LULUS" : "GAGAL";
    },
  },
  async mounted() {
    await this.loadBarcodeImages();
  },
  methods: {
    async generateBarcodeBase64(data) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, data, {
          format: "CODE128",
          width: 2,
          height: 100,
          displayValue: true,
        });
        resolve(canvas.toDataURL("image/png"));
      });
    },
    async loadBarcodeImages() {
      this.barcodeImages.production_result_code =
        await this.generateBarcodeBase64(this.component_barcode);
      this.barcodeImages.code_main_bag = await this.generateBarcodeBase64(
        this.blood_bag_number
      );
      this.barcodeLoaded = true;
    },
    // word docx
    async convertToWord() {
      this.isLoading = true;
      if (!this.barcodeLoaded) {
        await this.loadBarcodeImages();
      }

      try {
        const document = new Document({
          sections: [
            {
              properties: {
                page: {
                  size: {
                    width: 5902, // 10.41 cm in twips
                    height: 5761, // 10.16 cm in twips
                  },
                  margin: {
                    top: 10,
                    right: 284,
                    bottom: 10,
                    left: 284,
                  },
                  borders: {
                    pageBorders: {
                      offsetFrom: PageBorderOffsetFrom.PAGE,
                      display: PageBorderDisplay.FIRST_PAGE,
                    },
                  },
                },
              },
              children: [
                ...this.createHeader(),
                this.createContent(),
                this.createFooter(),
              ],
            },
          ],
        });

        const blob = await Packer.toBlob(document);
        saveAs(blob, `Produksi_${this.component_barcode}.docx`);
      } catch (error) {
        console.error("Error generating Word document:", error);
        // Handle the error appropriately, e.g., show an error message to the user
      } finally {
        this.isLoading = false;
      }
    },
    createHeader() {
      return [
        new Paragraph({
          children: [
            new TextRun({
              text: "UTD RS OTO ISKANDAR DI NATA",
              bold: true,
              size: 24,
            }),
          ],
          alignment: AlignmentType.CENTER,
          spacing: {
            after: 100,
            before: 100,
            line: 200,
            lineRule: LineRuleType.AUTO,
          },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921 Telp. 081312856201",
              size: 18,
            }),
          ],
          alignment: AlignmentType.CENTER,
          border: {
            bottom: {
              style: BorderStyle.DOUBLE,
              size: 6,
              color: "000000",
            },
          },
          spacing: { after: 200, before: 100 },
        }),
      ];
    },

    createContent() {
      const noBorders = {
        top: { style: BorderStyle.NONE, size: 0 },
        bottom: { style: BorderStyle.NONE, size: 0 },
        left: { style: BorderStyle.NONE, size: 0 },
        right: { style: BorderStyle.NONE, size: 0 },
        insideHorizontal: { style: BorderStyle.NONE, size: 0 },
        insideVertical: { style: BorderStyle.NONE, size: 0 },
      };

      return new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: noBorders,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: {
                  size: 60,
                  type: WidthType.PERCENTAGE,
                },
                borders: noBorders,
                children: [
                  new Paragraph({
                    text: `DONOR ${this.donor_type.toUpperCase()}`,
                    alignment: AlignmentType.CENTER,
                    heading: HeadingLevel.HEADING_3,
                  }),
                  this.createBarcodeImage(
                    this.barcodeImages.production_result_code
                  ),
                  new Paragraph({ text: `Produk : ${this.component}` }),
                  new Paragraph({ text: `Volume : ${this.volume} cc` }),
                  new Paragraph({ text: `Rhesus : ${this.blood_type_result}` }),
                  new Paragraph({ text: "Antibody : Negatif" }),
                  new Paragraph({ children: [] }),
                  new Paragraph({ text: `Aftap : ${this.aftapDate}` }),
                  new Paragraph({ text: `Prod : ${this.prodDate}` }),
                  new Paragraph({ text: `EXD : ${this.expDate}` }),
                  this.createBarcodeImage(this.barcodeImages.code_main_bag),
                ],
              }),
              new TableCell({
                width: {
                  size: 40,
                  type: WidthType.PERCENTAGE,
                },
                borders: noBorders,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `RH ${this.blood_type_result}`,
                        size: 40,
                        color: "000000",
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    heading: HeadingLevel.HEADING_1,
                    spacing: {
                      after: 200,
                      before: 400,
                    },
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "NON REAKTIF TERHADAP",
                        underline: {
                          type: UnderlineType.SINGLE,
                          color: "000000", // Warna hitam, Anda bisa mengubahnya jika diperlukan
                        },
                        size: 16,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                  ...this.nonReactiveTests.map(
                    (test, index) =>
                      new Paragraph({
                        text: `${index + 1}. ${test}`,
                        alignment: AlignmentType.LEFT,
                      })
                  ),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "LULUS",
                        size: 48,
                        bold: true,
                        color: "000000",
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    heading: HeadingLevel.HEADING_1,
                    spacing: {
                      before: 1000,
                    },
                    border: {
                      top: {
                        style: BorderStyle.SINGLE,
                        size: 6,
                        color: "000000",
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        size: 6,
                        color: "000000",
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        size: 6,
                        color: "000000",
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        size: 6,
                        color: "000000",
                      },
                    },
                    indent: {
                      left: 100,
                      right: 100,
                    },
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
            ],
          }),
        ],
      });
    },

    createFooter() {
      return new Paragraph({
        children: [
          new TextRun({ text: "Simpan Pada Suhu 2-6°C\n", size: 18 }),
          new TextRun({ text: "CPDA-1", size: 18, break: 1 }),
        ],
        alignment: AlignmentType.LEFT,
        spacing: {
          before: 200,
        },
      });
    },

    createBarcodeImage(base64String, width = 200, height = 60) {
      try {
        const imageData = base64String.split(",")[1];
        return new Paragraph({
          children: [
            new ImageRun({
              data: Buffer.from(imageData, "base64"),
              transformation: {
                width,
                height,
              },
            }),
          ],
          alignment: AlignmentType.CENTER,
        });
      } catch (error) {
        console.error("Error creating barcode image:", error);
        return new Paragraph({ text: "Barcode image not available" });
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: serif;
}

p,
li {
  font-size: 12px;
}
</style>
